import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { ModalLayout } from "../layout/modal";
import axiosInstance from "../../client/index.js";
import { Kurz } from "./Kurz.js";
import { PackSelect } from "./PackSelect";
import {
    ChevronLeftIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/outline";

import { Loading } from "../layout/Loading";
import CartModal from "../cart/CartModal";

const getStatusText = (status) => {
    switch (status) {
        case "0":
            return "volno";
        case "plno":
            return "Obsazeno";
        case "zruseno":
            return "Zrušeno";
        case "priprava":
            return "Připravujeme";
        default:
            return status;
    }
};

export const PackModal = (props) => {
    const course = props.course;
    const [packItems, setPackItems] = useState();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    useEffect(
        () => {
            setPackItems(course.pack_data);
        },
        // eslint-disable-next-line
        []
    );

    const onSubmit = async (data) => {
        setLoading(true);
        //console.log(data)
        try {
            const res = await axiosInstance.post(
                "cart/item_add/" + props.cartId + "/",
                {
                    itemId: data,
                    type: "eorder",
                    packId: course.mod_kurzy_id,
                    price: course.price_vat,
                }
            ); // Fetch the resource
            if (res.data) {
                props.setCart(res.data);
                setLoading(false);
                props.setModalContent({
                    component: CartModal,
                    params: {
                        lastCartItem: course.mod_kurzy_id,
                    },
                });
            }
            // Do your JSON handling here
        } catch (err) {
            console.log(err);
            setLoading(false);
            // This probably means your response is text, do you text handling here
        }
        //setItems(result.items)
        //setCart(result.cart)
    };

    return (
        <>
            <ModalLayout title={course.mod_kurzy_nazev}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="divide-y space-y-4 border-b ">
                        {packItems &&
                            packItems.map((packItem, i) => (
                                <div key={i} className="grid grid-cols-8">
                                    <div className="w-10 flex  mx-auto justify-center text-xl">
                                        <div className="bg-gray-200 rounded-full flex mt-2 w-5 h-5 items-center justify-center p-4 border border-gray-300">
                                            {i + 1}
                                        </div>
                                    </div>
                                    <div className="col-span-7 relative">
                                        <input
                                            {...register(
                                                "pack_item_" +
                                                    `${packItem.pack_items_id}`,
                                                { required: true }
                                            )}
                                            type="hidden"
                                            defaultValue={
                                                packItem.terminy &&
                                                packItem.terminy.length == 1
                                                    ? packItem.terminy[0]
                                                          .mod_turnusy_id
                                                    : null
                                            }
                                        />

                                        {packItem.terminy &&
                                        packItem.terminy.length == 1 ? (
                                            <Kurz
                                                course={packItem.terminy[0]}
                                                cartId={props.cartId}
                                                setCart={props.setCart}
                                                pack={true}
                                            />
                                        ) : (
                                            <PackSelect
                                                packItem={packItem}
                                                setValue={setValue}
                                                field={
                                                    "pack_item_" +
                                                    `${packItem.pack_items_id}`
                                                }
                                                clearErrors={clearErrors}
                                            />
                                        )}

                                        {errors[
                                            "pack_item_" +
                                                `${packItem.pack_items_id}`
                                        ] && (
                                            <div className="rounded-md bg-red-50 mt-1 p-2">
                                                <svg
                                                    className="h-5 w-5 text-red-400 inline mr-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                                <span className="text-sm text-red-700">
                                                    Vyberte prosím součást
                                                    balíčku
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="text-2xl p-2 text-right">
                        {parseInt(course?.price_vat).toLocaleString("cs")} Kč
                    </div>

                    <span className="grid grid-cols-2 justify-between py-5">
                        <div>
                            <button
                                type="button"
                                onClick={() => props.setModalIsOpen(false)}
                                className="flex items-center  text-gray-600 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-gray-100 rounded-md border border-gray-200 hover:bg-gray-200"
                            >
                                <ChevronLeftIcon className="w-8 " />
                                <span>Zpět</span>
                            </button>
                        </div>

                        <>
                            <div className="flex space-x-4 justify-end items-center">
                                {course?.obsazeno != "0" ? (
                                    <>
                                        <span className="flex space-x-2 items-center h-12 px-4 py-3 text-gray-600 bg-gray-200 rounded-md border border-gray-300    focus:outline-none">
                                            <ExclamationCircleIcon className="w-4" />
                                            <span>
                                                {" "}
                                                {getStatusText(
                                                    course?.obsazeno
                                                )}{" "}
                                            </span>
                                        </span>
                                    </>
                                ) : (
                                    <button
                                        type="submit"
                                        className="flex space-x-2 items-center h-12 px-4 py-3 text-gray-100 bg-green-600 rounded-md border border-green-700   hover:text-white hover:bg-green-700 focus:outline-none"
                                    >
                                        <svg
                                            id="Capa_1"
                                            className="w-4"
                                            fill="currentColor"
                                            viewBox="0 0 512 512"
                                        >
                                            <g>
                                                <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                                <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                                            </g>
                                        </svg>
                                        <span>Vložit do košíku </span>
                                    </button>
                                )}
                            </div>
                        </>
                    </span>
                </form>
            </ModalLayout>
            <Loading loading={loading} />
        </>
    );
};
