import React, { useState, useEffect } from "react";
import { PackModal } from "./PackModal";
import { Info } from "./Info";
import { KurzModal } from "./KurzModal";
import {
    ExclamationCircleIcon,
    InformationCircleIcon,
} from "@heroicons/react/outline";
import { Popover } from "@headlessui/react";
import { format } from "date-fns";

const getStatusText = (status) => {
    switch (status) {
        case "0":
            return "volno";
        case "plno":
            return "Obsazeno";
        case "zruseno":
            return "Zrušeno";
        case "priprava":
            return "Připravujeme";
        default:
            return status;
    }
};

export const badges = {
    joga: { alias: "joga", color: "pink", colors: "bg-pink-100 text-pink-700" },
    tanec: {
        alias: "tanec",
        color: "green",
        colors: "bg-green-100 text-green-700",
    },
    divadlo: {
        alias: "divadlo",
        color: "blue",
        colors: "bg-blue-100 text-blue-700",
    },
    "audio-video": {
        alias: "audio-video",
        color: "",
        colors: "bg-pink-100 text-pink-700",
    },
    zpev: {
        alias: "zpev",
        color: "yellow",
        colors: "bg-yellow-100 text-yellow-700",
    },
    "hudba-zpev": {
        alias: "hudba-zpev",
        color: "yellow",
        colors: "bg-yellow-100 text-yellow-700",
    },
    "zpev-hudba": {
        alias: "zpev-hudba",
        color: "yellow",
        colors: "bg-yellow-100 text-yellow-700",
    },
    "kreativni-psani": {
        alias: "kreativni-psani",
        color: "",
        colors: "bg-yellow-100 text-yellow-700",
    },
    "hudba-zpev-ii": {
        alias: "hudba-zpev-ii",
        color: "yellow",
        colors: "bg-yellow-100 text-yellow-700",
    },
    "pro-deti": {
        alias: "pro-deti",
        color: "",
        colors: "bg-yellow-100 text-yellow-700",
    },
};

export const Balicek = (props) => {
    const course = props.course;
    const cartItems = props.cart?.items;
    const mesto = props.mesto;
    const [loading, setLoading] = useState(false);
    const [ordered, setOrdered] = useState(false);

    const terminOd =
        course?.mod_terminy_od &&
        format(new Date(course?.mod_terminy_od), "d. M.");
    const terminDo =
        course?.mod_terminy_do &&
        format(new Date(course?.mod_terminy_do), "d. M. y");
    const termin = terminOd + " - " + terminDo;

    function selectCourse() {
        props.setModalContent({
            component: PackModal,
            params: {
                course: course,
            },
        });
        props.setModalIsOpen(true);
    }

    useEffect(
        () => {
            setOrdered(false);
            cartItems &&
                cartItems.map((item, i) => {
                    item?.itemId &&
                        item.pack_items.length > 0 &&
                        item?.itemId === course.mod_kurzy_id &&
                        setOrdered(true);
                });
        },
        // eslint-disable-next-line
        [cartItems, mesto, course]
    );

    return (
        <>
            <div
                className={
                    "lg:my-0 bg-white space-y-2 lg:space-y-0  lg:grid grid-cols-1  lg:grid-cols-12 lg:gap-3 items-start  px-6 lg:p-3 sm:text-sm hover:bg-gray-50  my-5 py-8"
                }
            >
                <div
                    className={
                        "border-b pb-2 lg:border-none lg:pb-0 border-gray-300 flex justify-between lg:grid  lg:col-span-2 "
                    }
                >
                    <span>{termin}</span>
                    <span className="flex lg:text-xs text-gray-500">
                        {course?.cas}
                    </span>
                </div>

                <div
                    className={
                        "flex justify-between  lg:col-span-4 cursor-pointer  "
                    }
                    onClick={() => selectCourse()}
                >
                    <div className="lg:space-y-2">
                        <span className=" font-bold block text-blue-700">
                            {course?.mod_kurzy_nazev}{" "}
                        </span>
                        <span className="hidden lg:inline text-gray-500">
                            Počet kurzů v balíčku:{" "}
                            <span className="font-medium">
                                {course?.pack_data?.length}
                            </span>
                        </span>
                    </div>
                    <div className="inline-block mt-1 lg:hidden ">
                        {course?.mod_kurzy_categories_name && (
                            <span
                                className={
                                    "inline-block w-auto truncate items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 " +
                                    (badges[course?.mod_kurzy_categories_url] &&
                                        "bg-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-100 text-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-700")
                                }
                            >
                                {course?.mod_kurzy_categories_name}
                            </span>
                        )}
                    </div>
                </div>

                <div className="hidden lg:block">
                    {course?.mod_kurzy_categories_name && (
                        <span
                            className={
                                "inline-block overflow-x-hidden w-full text-center  items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 " +
                                (badges[course?.mod_kurzy_categories_url] &&
                                    "bg-" +
                                        badges[course?.mod_kurzy_categories_url]
                                            ?.color +
                                        "-100 text-" +
                                        badges[course?.mod_kurzy_categories_url]
                                            ?.color +
                                        "-700")
                            }
                        >
                            {course?.mod_kurzy_categories_name}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "  grid grid-cols-2  text-sm lg:text-sm   lg:col-span-1 "
                    }
                >
                    <div className="block lg:hidden mb-2 lg:mb-0">
                        <span>Počet kurzů v balíčku</span>
                    </div>
                    <div className="text-right lg:text-left lg:hidden">
                        {course?.pack_data?.length}
                    </div>

                    <div className="block lg:hidden mb-2">
                        <span>Cena</span>
                    </div>
                    <div className="text-right lg:hidden lg:col-span-2">
                        <span>
                            {parseInt(course?.price_vat).toLocaleString("cs")}{" "}
                            Kč
                        </span>
                    </div>
                </div>

                <div className="col-span-2 lg:col-span-2 ">
                    {course.lektori &&
                        course.lektori.map((lektor, lektorIdx) => (
                            <div key={lektorIdx}>{lektor.name}</div>
                        ))}
                </div>

                <div className="  sm:col-span-2 text-right lg:grid lg:grid-cols-2 items-center justify-end gap-3">
                    <span className="hidden lg:block">
                        <>
                            {parseInt(course?.price_vat).toLocaleString("cs")}{" "}
                            Kč
                        </>
                    </span>
                    <span className="grid justify-items-end">
                        {course?.obsazeno != "0" ? (
                            <>
                                <button
                                    disabled
                                    className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 text-xs py-1 text-gray-500 bg-gray-200 rounded-md border border-gray-300  focus:outline-none opacity-50"
                                >
                                    <ExclamationCircleIcon className="w-4" />
                                    <span>
                                        {getStatusText(course?.obsazeno)}
                                    </span>
                                </button>
                            </>
                        ) : ordered ? (
                            <button
                                disabled
                                className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 text-xs py-1 text-gray-500 bg-gray-200 rounded-md border border-gray-300  focus:outline-none opacity-50"
                            >
                                <svg
                                    id="Capa_1"
                                    className="w-4"
                                    fill="currentColor"
                                    viewBox="0 0 512 512"
                                >
                                    <g>
                                        <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                        <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                                    </g>
                                </svg>
                                <span>V košíku</span>
                            </button>
                        ) : (
                            <>
                                {!loading ? (
                                    <>
                                        <button
                                            onClick={() => selectCourse()}
                                            className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 py-1 text-gray-100 bg-green-600 rounded-md border border-green-700   hover:text-white hover:bg-green-700 focus:outline-none"
                                        >
                                            <svg
                                                id="Capa_1"
                                                className="w-4"
                                                fill="currentColor"
                                                viewBox="0 0 512 512"
                                            >
                                                <g>
                                                    <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                                    <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                                                </g>
                                            </svg>
                                            <span>Objednat </span>
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className="flex mx-auto text-blue-600 h-8  px-2 py-1 focus:outline-none">
                                            <svg
                                                className="w-5 animate-spin "
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
};

export const Kurz = (props) => {
    const course = props.course;
    const pack = props.pack;
    const mesto = props.mesto;
    const cartItems = props.cart?.items;
    const [loading, setLoading] = useState(false);
    const [ordered, setOrdered] = useState(false);

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    const terminOd =
        course?.mod_terminy_od &&
        format(new Date(course?.mod_terminy_od), "d. M.");
    const terminDo =
        course?.mod_terminy_do &&
        format(new Date(course?.mod_terminy_do), "d. M. y");

    let termin;
    datesAreOnSameDay(
        new Date(course?.mod_terminy_od),
        new Date(course?.mod_terminy_do)
    )
        ? (termin = terminDo)
        : (termin = terminOd + " - " + terminDo);

    function selectCourse() {
        props.setModalContent({
            component: KurzModal,
            params: {
                course: course,
            },
        });
        props.setModalIsOpen(true);
    }

    function CourseInfo(text) {
        props.setModalContent({
            component: Info,
            params: {
                text: text,
            },
        });
        props.setModalIsOpen(true);
    }

    useEffect(
        () => {
            setOrdered(false);
            cartItems &&
                cartItems.map((item, i) => {
                    if (item.pack_items.length > 0) {
                        item.pack_items.map((packItem, z) => {
                            packItem?.mod_turnusy_id ===
                                course.mod_turnusy_id && setOrdered(true);
                        });
                    } else {
                        item?.itemId &&
                            item?.itemId === course.mod_turnusy_id &&
                            setOrdered(true);
                    }
                });
        },
        // eslint-disable-next-line
        [cartItems, mesto, course]
    );

    return (
        <>
            <div
                className={
                    "lg:my-0 bg-white space-y-2 lg:space-y-0  lg:grid grid-cols-1  lg:grid-cols-12 lg:gap-3 items-start  px-6 lg:p-3 sm:text-sm " +
                    (pack ? "my-0 py-2" : "my-5 py-8 hover:bg-gray-50 ")
                }
            >
                <div
                    className={
                        "border-b pb-2 lg:border-none lg:pb-0 border-gray-300 flex justify-between lg:grid " +
                        (pack ? "lg:col-span-3 " : "lg:col-span-2 ")
                    }
                >
                    {course?.mod_turnusy_program ? (
                        <Popover className="relative">
                            <Popover.Button className="focus:outline-none">
                                <span>{termin}</span>
                                <span className="flex items-center lg:text-xs text-gray-500">
                                    {course?.cas}{" "}
                                    {course?.mod_turnusy_program && (
                                        <span title="Více o času konání">
                                            <InformationCircleIcon className="w-5 lg:w-4 ml-2 text-blue-600" />{" "}
                                        </span>
                                    )}
                                </span>
                            </Popover.Button>

                            <Popover.Panel className="absolute z-10 bg-white shadow-md p-3 rounded-md border-gray-200 border -ml-2 w-52">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: course?.mod_turnusy_program,
                                    }}
                                ></div>
                            </Popover.Panel>
                        </Popover>
                    ) : (
                        <>
                            <span>{termin}</span>
                            <span className="flex items-center lg:text-xs text-gray-500">
                                {course?.cas}
                            </span>
                        </>
                    )}
                </div>

                <div
                    className={
                        "flex justify-between " +
                        (pack ? " lg:col-span-5 " : "lg:col-span-4  ") +
                        (course?.mod_turnusy_text ? " cursor-pointer " : "")
                    }
                >
                    <div className="lg:space-y-2 ">
                        {course?.mod_turnusy_text ? (
                            <span
                                className=" block font-bold  text-blue-700"
                                onClick={() =>
                                    CourseInfo(course?.mod_turnusy_text)
                                }
                            >
                                {course?.mod_kurzy_nazev}{" "}
                                {course?.mod_turnusy_text && (
                                    <span title="Více o kurzu">
                                        <InformationCircleIcon className="inline w-5 lg:w-4 ml-2 text-blue-600" />{" "}
                                    </span>
                                )}
                            </span>
                        ) : (
                            <>
                                <span
                                    className={
                                        "block font-bold    text-blue-700 "
                                    }
                                >
                                    {course?.mod_kurzy_nazev}{" "}
                                </span>
                            </>
                        )}

                        <span className="hidden lg:inline text-gray-500">
                            Pokročilost{" "}
                            <span className="font-medium">
                                {course?.mod_turnusy_nazev}
                            </span>
                        </span>
                        <span className="hidden lg:inline lg:ml-3 text-gray-500 mx-auto">
                            {" "}
                            {course?.mod_turnusy_mesto}
                        </span>
                    </div>
                    <div className="inline-block mt-1 lg:hidden ">
                        {course?.mod_kurzy_categories_name && (
                            <span
                                className={
                                    "inline-block w-auto truncate items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 " +
                                    (badges[course?.mod_kurzy_categories_url] &&
                                        "bg-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-100 text-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-700")
                                }
                            >
                                {course?.mod_kurzy_categories_name}
                            </span>
                        )}
                    </div>
                </div>
                {!pack && (
                    <div className="hidden lg:block col-span-2 ">
                        {course?.mod_kurzy_categories_name && (
                            <span
                                className={
                                    "inline-block overflow-x-hidden  text-center  items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 " +
                                    (badges[course?.mod_kurzy_categories_url] &&
                                        "bg-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-100 text-" +
                                            badges[
                                                course?.mod_kurzy_categories_url
                                            ]?.color +
                                            "-700")
                                }
                            >
                                {course?.mod_kurzy_categories_name}
                            </span>
                        )}
                    </div>
                )}

                <div
                    className={
                        " grid grid-cols-2  text-sm lg:text-sm   " +
                        (pack ? " lg:col-span-3 " : "lg:col-span-2 ")
                    }
                >
                    <div className="block lg:hidden mb-2 lg:mb-0">
                        <span>Lektor</span>
                    </div>
                    <div className="text-right lg:text-left lg:col-span-2">
                        {course.lektori &&
                            course.lektori.map((lektor, lektorIdx) => (
                                <div key={lektorIdx}>
                                    {lektor.link ? (
                                        <>
                                            <a
                                                href={lektor.link}
                                                target="_blank"
                                                key={lektorIdx}
                                                className="truncate underline hover:no-underline text-blue-700"
                                                title={lektor.name}
                                            >
                                                {lektor.name}
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                key={lektorIdx}
                                                className="truncate"
                                                title={lektor.name}
                                            >
                                                {lektor.name}
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                    </div>

                    <div className="block lg:hidden mb-2 lg:mb-0">
                        <span>Pokročilost</span>
                    </div>
                    <div className="block lg:hidden text-right lg:text-left lg:col-span-2">
                        {course?.mod_turnusy_nazev}
                    </div>

                    <div className="block lg:hidden mb-2 lg:mb-0">
                        <span>Místo</span>
                    </div>
                    <div className="block lg:hidden text-right lg:text-left lg:col-span-2">
                        {course?.mod_turnusy_mesto}
                    </div>

                    {!pack && (
                        <div className="block lg:hidden mb-2">
                            <span>Cena</span>
                        </div>
                    )}
                    {!pack && (
                        <div className="text-right lg:hidden lg:col-span-2">
                            <span>
                                {parseInt(course?.price_vat).toLocaleString(
                                    "cs"
                                )}{" "}
                                Kč
                            </span>
                        </div>
                    )}
                </div>

                {!pack && (
                    <div className="  sm:col-span-2 text-right lg:grid lg:grid-cols-2 items-center justify-end gap-3">
                        <span className="hidden lg:block">
                            <>
                                {parseInt(course?.price_vat).toLocaleString(
                                    "cs"
                                )}{" "}
                                Kč
                            </>
                        </span>
                        <span className="grid justify-items-end">
                            {course?.obsazeno != "0" ? (
                                <>
                                    <button
                                        disabled
                                        className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 text-xs py-1 text-gray-500 bg-gray-200 rounded-md border border-gray-300  focus:outline-none opacity-50"
                                    >
                                        <ExclamationCircleIcon className="w-4" />
                                        <span>
                                            {getStatusText(course?.obsazeno)}
                                        </span>
                                    </button>
                                </>
                            ) : ordered ? (
                                <button
                                    disabled
                                    className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 text-xs py-1 text-gray-500 bg-gray-200 rounded-md border border-gray-300  focus:outline-none opacity-50"
                                >
                                    <svg
                                        id="Capa_1"
                                        className="w-4"
                                        fill="currentColor"
                                        viewBox="0 0 512 512"
                                    >
                                        <g>
                                            <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                            <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                                        </g>
                                    </svg>
                                    <span>V košíku</span>
                                </button>
                            ) : (
                                <>
                                    {!loading ? (
                                        <>
                                            <button
                                                onClick={() => selectCourse()}
                                                className="flex space-x-2 items-center h-12 lg:h-8 px-5 lg:px-2 py-1 text-gray-100 bg-green-600 rounded-md border border-green-700   hover:text-white hover:bg-green-700 focus:outline-none"
                                            >
                                                <svg
                                                    id="Capa_1"
                                                    className="w-4"
                                                    fill="currentColor"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <g>
                                                        <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
                                                        <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
                                                    </g>
                                                </svg>
                                                <span>Objednat </span>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className="flex mx-auto text-blue-600 h-8  px-2 py-1 focus:outline-none">
                                                <svg
                                                    className="w-5 animate-spin "
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </span>
                    </div>
                )}
            </div>
        </>
    );
};
